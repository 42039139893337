@import './fonts.scss';

:root {
  --ion-item-background: #fff;
  --font-size-sm: 13px;
  --background-tan: #e8e4de;
  --background-unread: #eaf1fd;
}

.bg-light {
  --background: var(--background-tan);
}

.bg-transparent {
  --background: transparent;
}

a {
  text-decoration: none;
}

ion-item {
  --border-color: rgba(var(--ion-color-medium-rgb), 0.32);
}

ion-button {
  font-weight: 700;

  &.shape-round {
    --padding-start: 12px;
    --padding-end: 12px;
  }
}

ion-text ion-icon, .icon-align-middle {
  vertical-align: middle;
}

ion-thumbnail {
  border-radius: 2px;
  overflow: hidden;
}

ion-item.text-wrap ion-label {
  text-overflow: inherit !important;
  white-space: inherit !important;
}

ion-chip {
  font-weight: 700;

  &.chip-active {
    --background: var(--ion-color-success-shade);
    --color: #fff;
  }
}

.no-scroll {
  --overflow: hidden;
}

.has-fab {
  --padding-bottom: 72px;
  padding-bottom: var(--padding-bottom);
}

.toolbar-custom {
  ion-back-button {
    --color: var(--ion-color-medium);
  }
}

.toolbar-search {
  --border-color: rgba(var(--ion-color-medium-rgb), 0.32);

  ion-searchbar {
    --background: var(--ion-color-light);

    input {
      border-radius: 4px !important;
    }
  }

  &.content-search {
    &.ios {
      padding-top: 16px;
    }
  }
}

.panel {
  background-color: var(--ion-item-background);
  margin-bottom: 8px;

  .panel-header {
    padding: 12px 16px;

    ion-col {
      padding: 0;
    }
  }

  .panel-body {
    padding: 12px;

    &.no-padding {
      padding: 0;
    }

    &.has-grid {
      padding: 0 8px 8px;
    }

    &.no-padding-top {
      padding-top: 0;
    }
  }

  .panel-footer {
    border-top: 1px solid var(--ion-color-light);
  }

  &.bg-reverted {
    background-color: var(--ion-color-primary-shade);
    color: #fff;
  }

  .button-edit {
    position: absolute;
    right: -12px;
    top: -10px;
  }
}

.modal-custom-half {
  .modal-wrapper {
    height: 380px;
  }
}

.segment-custom {
  ion-segment-button {
    --border-radius: 0;
    --ripple-color: transparent;
    font-size: 17px;
    color: var(--ion-color-medium);
    text-transform: inherit;
    letter-spacing: inherit;

    &.segment-button-checked {
      color: var(--ion-color-primary);
    }
  }
}

.avatar .avatar-button {
  position: absolute;
  right: 0;
  bottom: 0;
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid var(--ion-color-medium);
  vertical-align: middle;
  background-color: var(--ion-color-light);
}
