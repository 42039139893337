:root {
  /** primary **/
  --ion-color-primary: #00585E;
  --ion-color-primary-rgb: 48,102,187;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #4B878B;
  --ion-color-primary-tint: #48AFB6;

  /** white **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255,255,255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0,0,0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  /** black **/
  --ion-color-black: #000000;
  --ion-color-black-rgb: 0,0,0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255,255,255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;

  /** GENERAL **/
  --ion-text-color: #333333;
  --ion-background-color: #ffffff;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}
